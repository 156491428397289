import React from 'react'
import JitsiConnection from '../../components/JitsiConnection/JitsiConnection'
import { useConferenceStore } from "../../store/ConferenceStore"
import { useConnectionStore } from "../../store/ConnectionStore"
import { useState, useRef, useEffect } from "react"
import styled from 'styled-components'
import './Schaltzentrale.css'
import {Scenes, UserOptions, MessengerAll, AdminTopBar} from "./Schaltzentrale"
import scenes_json from './assets/Skriptablauf.json';

import { Track, User, ConferenceStore } from "../../store/ConferenceStore";

import { Room } from '../../components/Room/Room'
import { Users } from '../../components/User/Users'

const Admin = styled.div`
  position:relative;
  text-align: left;
  overflow-y: scroll;
  height: 100%;
`

export const Titel = (props)=>
{
  const TitelStyle={
    fontSize: '25pt',
    marginLeft: '15px'
  }
  return (<div style={TitelStyle}>{props.children}</div>)
}

const ScenesDiv = styled.div`
  position:relative;
  top:100px;
`


const OrderButtonDiv = styled.div`
  margin-left:40px;
  margin-bottom: 10px;
  flex: 0 0 300px;
`

const Button = styled.button`
  border: 1px solid transparent;
  border-radius: 3px;
  padding:5px;
  transition: all 0.1s;
  &:hover {
    color: white;
  }
  float:left;
`

const Minitext = styled.div`
  margin-left: 10px;
  font-size: 6pt;
`
const UserWrap = styled.div`
  position: relative;
  font-size:10pt;
  left:400px;
  display:flex;
  flex-direction:column;
  margin-top:200px
`

const UserCollumnName = styled.div`
  width: 200px;
  flex: 0 0 200px;
`
const UserCollumnStatus = styled.div`
  width: 200px;
  flex: 0 0 200px;
  line-break: anywhere;
`
const UserCollumnReply = styled.div`
  flex: 1;
  line-break: anywhere;
`

export const InviteButton = ({id, content})=>{
  const [clicked, setClicked] = useState(true);

  const InviteAction = () => {
    setClicked(false);
    const conference = useConferenceStore.getState().conferenceObject

    conference?.sendCommandOnce("redirect", { value: 0, attributes: {
      ids: id,
      content: content
       }
  })
  }

  //
  return(
    <>
    <Button className={clicked ? "" : "btnClicked"} onClick={InviteAction}>Einlass</Button>
    </>
  )
}




export const UserListInvite = ({match}) => {
  const users = useConferenceStore(state => state.users) //.getState().users
  const [user_list, setUser_list] = useState([]);
  let u = []

  let content= "http://konferenz.pulk-fiktion.de/vorstellung/" + match
  
  useEffect(()=> {
        Object.keys(users).forEach((key) => {
          u.push({id:users[key].id, name:users[key].user._displayName, reply:users[key].reply})
        })
        setUser_list(u)
        },[users])


    return (
      <>
        {user_list.map((user) => (
          <div key={user.id}>
            <InviteButton id={user.id} content={content} />
            <UserCollumnName><b>{user.name}</b><small>({user.id})</small></UserCollumnName>
            <UserCollumnStatus>{user.status}</UserCollumnStatus>
          </div>  
        ))
        }
      </>
  )
}




export const SchaltzentraleFoyer = ({id}) => {
  useEffect(()=> { document.title = "Schaltzentrale Foyer"; })

  return(
    <Admin>
      {id.id}
    <AdminTopBar/>
    <UserWrap>
      Users:
           <UserListInvite match={id}/>
    </UserWrap>
  
          <JitsiConnection id={"foyer_"+id}/>
          {/*<Room>
          </Room> 
          */}
        <Scenes data={scenes_json} />
    </Admin>
  )
}