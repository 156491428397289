import React, { useCallback, useEffect } from 'react';
import { useConferenceStore } from './../../store/ConferenceStore';
import { ReloadHint } from '../ReloadHint/ReloadHint';
import { AudioTrack } from './AudioTrack';
import { MuteIndicator } from './MuteIndicator';
import { HighlightIndicator } from './HighlightIndicator';
import { SpeakerIndicator } from './SpeakerIndicator';
import { VideoTrack } from './VideoTrack';
import { NameTag } from '../NameTag/NameTag';
import { Flipped } from "./Flipped"

export const User = ({id, user}) => {

  const myPos = useConferenceStore(useCallback(store => store.users[id]['pos'], [id]))
  const myVolume = useConferenceStore(useCallback(store => store.users[id]['volume'], [id]))
  const isMute = useConferenceStore(useCallback(store => store.users[id]['mute'],[id]))

  const isFlipped = useConferenceStore(useCallback(store => store.users[id]['flipped'],[id]))
  const isHighlighted = useConferenceStore(useCallback(store => store.users[id]['highlighted'],[id]))
  const isHighlightedSpeaker = useConferenceStore(useCallback(store => store.users[id]['highlighted_speaker'],[id]))
  const isHidden = useConferenceStore(useCallback(store => store.users[id]['hidden'],[id]))
  //! implement hidden 

  const calculateVolume = useConferenceStore(useCallback(store => store.calculateVolume, []))
  useEffect(() => {
    calculateVolume(id)
  },[id, calculateVolume, myPos])

  if (isHidden){
    return null
  }
  else
  {
  return(
    <div style={{position:'absolute', left:`${myPos.x}px`, top:`${myPos.y}px`}} className="userContainer" >
      <Flipped isFlipped={isFlipped}>
      <VideoTrack id={id} />
      </Flipped>
      <ReloadHint />
      <AudioTrack id={id} volume={myVolume} />
      <NameTag>{user?.user?._displayName || 'Dein Name'}</NameTag>
      <div>Volume {Math.round(myVolume * 11)}</div>
      {isMute && <MuteIndicator/>}
      {isHighlighted && <HighlightIndicator/>}
      {isHighlightedSpeaker && <SpeakerIndicator/>}
      
      
    </div>
  )
  }
}


