import * as React from 'react';
import styled from 'styled-components';
import muted from './../../addons/Sde/assets/stumm_kl.png'


const Indicator = styled.div`
  position:absolute;
  background-image:url(${muted});
  top:273px;
  left:220px;
  width:100px;
  height:84px;
  filter: drop-shadow(5px 3px 3px #222);
  z-index:0;
  filter: drop-shadow();
`

export const MuteIndicator = () => {
  return (
    <Indicator >
      {/*<Muted />*/}
    </Indicator>
  )
}