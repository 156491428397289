import { useConferenceStore } from '../../store/ConferenceStore';
import shallow from 'zustand/shallow'

import styled from 'styled-components';
import background_file from "../../addons/Sde/assets/Schriftrolle.png" 

const AdditionalRoomContainer = styled.div`
  position:absolute;
  z-index: -1;
  width:1100px;
  height:1550px;
  top: 2500px;
  left: 1961px;
  background-image:url(${background_file});
  background-size: cover;
  background-repeat: no-repeat;
  font-size:25pt;
`
const shape1 = styled.div`
float: left;
shape-outside: polygon(0 0, 35% 0, 21% 13%, 24% 81%, 41% 100%, 0 100%);
background-color:green;
`
const shape2 = styled.div`
float:right;
shape-outside:  polygon(75% 0, 100% 1%, 100% 100%, 72% 100%, 78% 47%, 66% 17%);
background-color:green;
`


const List = styled.div`
transform: rotate3d(4.6, 0, 0.5, 25deg);
padding-left: 279px;
padding-right: 349px;
padding-top: 11px;
`

const Li = styled.li`
  padding-bottom:20px;
`

export const AdditionalRoom = () => {
  
  //const [rules_list, setRules_list] = useState([]);
  //const unsub1 = useLocalStore.subscribe(console.log)
  //const unsub2 = useLocalStore.subscribe(console.log, state => state.rules)
  const rules = useConferenceStore(state => state.rules) 
  const show_rules = useConferenceStore(state => state.show_rules)  //.getState().users

  //const rules = ["asdasd"] // useLocalStore(state => Object.values(state.rules), shallow)
  //values

  if (show_rules)
  return (
          <AdditionalRoomContainer>
            <shape1></shape1>
            <shape2></shape2>
            <List>
            <ul>
              {rules.map((rule) => (
                <Li key={rule}>{rule}</Li>
              ))
              }

            </ul>
            </List>
            
          </AdditionalRoomContainer>
        )
  else
      return null

}