import React from 'react'
import styled from 'styled-components'
import { Footer } from '../../components/Footer/Footer'
import {BigHeadline} from './../../components/common/BigHeadline'
import { SubHeadline } from '../../components/common/SubHeadline'
import chrome1 from './../../addons/Sde/assets/chrome1.png'
import chrome2 from './../../addons/Sde/assets/chrome2.png'
import chrome3 from './../../addons/Sde/assets/chrome3.png'
import chrome4 from './../../addons/Sde/assets/chrome4.png'
import chrome5 from './../../addons/Sde/assets/chrome5.png'
import chrome6 from './../../addons/Sde/assets/chrome6.png'


const BigHeadContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

const CenterContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	position:relative !important;
	overflow: auto;
`

const Img = styled.img`
	margin-top: 40px;
	width:80%;
`

export const Setup = () => {
	return (
		<React.Fragment>
			{/*	<Info>
				Welcome to our Prototype
				<br />
				Please use <b>Safari</b> or <b>Chrome</b> for now for a stable Experience
			</Info>*/}
			<CenterContainer>
			<BigHeadContainer>
				<BigHeadline>Konferenz</BigHeadline>
			</BigHeadContainer>
			<SubHeadline>Chrome Camera Check</SubHeadline>
				
			<div style={{width:"600px", marginLeft: "auto",marginRight: "auto",height: "100%"}}>

				<Img src={chrome1}/>
				<Img src={chrome2}/>
				<Img src={chrome3}/>
				<Img src={chrome4}/>
				<Img src={chrome5}/>
				<Img src={chrome6}/>

			</div>

			</CenterContainer>
			<Footer />
		</React.Fragment>
	)
}
