import React from 'react';
import styled from 'styled-components';

import { useLocalStore } from "../../../store/LocalStore"

const FlippedDiv = styled.div`
transform: rotate(180deg);
`

export const Flipped = (props) => {
  const isFlipped = props.isFlipped
  if (isFlipped){
  return (
    <FlippedDiv>{props.children}</FlippedDiv>
  )}
  else
    return <div>{props.children}</div>
}

