import { useLocalStore } from "../../store/LocalStore"
import {IoMdClose} from 'react-icons/io'
import styled from 'styled-components'
import {useEffect } from 'react'
import nachricht from "./assets/sounds/nachricht.mp3"
import background from "./assets/Botbeschaftsrahmen.png"

const Container = styled.div`
  margin-top:20px
  margin: auto;
  text-align: left;
  min-height: 120px;
  min-width: 30%;
  max-width: 60%;
  z-index: 5000;

  border-image-source: url(${background}); /*path of the border image */
  border-image-slice: 180 177 161 151 fill;
  border-image-width: 60px 60px 60px 60px;
  border-image-outset: 17px 16px 12px 18px;
  border-image-repeat: stretch stretch; 
`
const MessageField = styled.div`
	height: auto;
  line-height: 50px;
  text-align: center;
  margin-top:20px;
  margin-bottom:30px;
  padding: 40px;

  color: ${props => props.theme.primary['1']};
  font-size: 24pt;
	box-sizing: border-box;
  width: 100%;
`

const StyledClose = styled(IoMdClose)`
  position: absolute;
  right: 5px;
  top: 5px;
`


// export const Info = ({ children }) => {
//   // const [ visible, toggleVisible ] = React.useState(true)
//   const visible = useInfoStore(store => store.show)
//   const setHidden = useInfoStore(store => store.setHidden)

//     const setHidden = () => {visible = false}

//   if (visible) {
//     return (
//       <div onClick={setHidden}>
//         <InfoBox>
//           {children}
//           <StyledClose />
//         </InfoBox>
//       </div>
//     )
//   } else {
//     return null
//   }
// }

export const MessageBox = () => {
  const message = useLocalStore((store) =>  store.message)
  useEffect(() => {
      const timer = setTimeout(function() {
        useLocalStore.setState({hasMessage: false})
      }, 20000);
      return () => clearTimeout(timer); // clear prior timeout
  })

return(
    <Container>
        <MessageField>{message}</MessageField>
        <audio autoPlay={true} src={nachricht} onPlay={e => console.log("onPlay")}/>
    </Container>
  )
}