import { useLocalStore } from "../../store/LocalStore"
import React, { useEffect, useState, useRef } from "react";
import './Timer.css'
import styled from 'styled-components';

export const TimerContainer = styled.div`
	font-family:'Seven Segment',Sans-Serif;
	font-weight: 800;
	font-size:  60pt;
	margin-top:10px;
	background-color: #fff;
	color: red;
	min-width:200px;
	text-align:center;
	border:7px solid #666;
	padding:3px;
	border-radius: 0.5rem;
`
export const Monospace = styled.div`
    display: inline-block;
    width: 38px;
    text-align: center;
`
export const MonospaceDot = styled.div`
    display: inline-block;
    width: 14px;
    text-align: center;
`

export const Timer = () => {
	const countdown = useLocalStore((store) =>  store.countdownValue)
  const [timer, setTimer] = useState(countdown);
  const [timer_display, setTimer_display] = useState([]);

  let intervalRef = useRef();

  const decreaseNum = () => {
 	
  	setTimer(prev => prev - 1);

	  	/// 
	if (timer < 3) { //start sound a bit earlier
		useLocalStore.setState({currentMusic: ""})
		useLocalStore.setState({currentSound: "FX2_Time_Out_1"})
	}

  	if (timer >= 0) { //start sound a bit earlier
	    let minutes = parseInt(timer / 60, 10);
	    let seconds = parseInt(timer % 60, 10);

	    minutes = minutes < 10 ? "0" + minutes : minutes;
	    seconds = seconds < 10 ? "0" + seconds : seconds;

	    let output = Array.from(String(minutes), Number).concat(":").concat(Array.from(String(seconds), Number))
	    //console.debug(output)
	  	setTimer_display(output)
  	}
  	else{
  		console.log ("times up")
  		//! geht nicht
  		clearInterval(intervalRef.current)

			
  		// remove after 5 seconds
			setTimeout(function() {
			  useLocalStore.setState({hasCountdown: false})
			}, 5000);

  	}
  }

  useEffect(() => {
    intervalRef.current = setInterval(decreaseNum, 1000);
    return () => clearInterval(intervalRef.current);
  }, [timer, countdown]);


const MonospaceComponent = (props)=>{
	return (<Monospace>{props.children}</Monospace>)
} 


const TimerComponent = ({ timerArray }) => {
  return (
    <>
      {timerArray.map((digit,i) => <MonospaceComponent key={i}>{digit}</MonospaceComponent>)}
    </>
  )
}


return(	<TimerContainer>
		<TimerComponent timerArray={timer_display} /> 
	</TimerContainer>

	)
}