import * as React from 'react';
import styled from "styled-components";
import kissingCat from './../../assets/kissingCat.svg'
import loveCat from './../../assets/loveCat.svg'

const StyledHeader = styled.a`
  display: flex;
  margin-top:10px;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 10px;
  left:10px;
  z-index: 10000;
  font-weight: 500;
  font-size: 1.25rem;
  text-decoration: none;
`

export const Header = ({children}) => (
  <StyledHeader>{children}</StyledHeader>
)