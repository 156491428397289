import React, { useCallback } from 'react';
import { Button } from './../../common/Buttons/Button';
import {FaMicrophoneSlash, FaMicrophone} from 'react-icons/fa'
import { useLocalStore } from './../../../store/LocalStore';
import stumm from '../../../addons/Sde/assets/stumm_btn.png'
import sprechen from '../../../addons/Sde/assets/sprechen_btn.png'


export const MuteButton = () => {

  const toggleMute = useLocalStore(store => store.toggleMute)
  const mute = useLocalStore(store => store.mute)

  // if(mute) {
  //   return <Button type="danger" onClick={toggleMute}><FaMicrophoneSlash/> Unmute</Button>
  // } else {
  //   return <Button type="secondary" onClick={toggleMute}><FaMicrophone/> Mute</Button>
  // }

  if(mute) {
    return <button style={{border: 0, background: "transparent"}} onClick={toggleMute}>
     <img width="300px" src={stumm} onClick={toggleMute} />
     </button>
  } else {
    return <button style={{border: 0, background: "transparent"}} onClick={toggleMute}>
    <img width="300px" src={sprechen} onClick={toggleMute} />
    </button>
  }

  
}