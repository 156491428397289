import produce from 'immer';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import create from 'zustand';
import { conferenceOptions } from '../components/JitsiConnection/jitsiOptions';
import { getVolumeByDistance } from '../utils/VectorHelpers';
import { useConnectionStore } from './ConnectionStore';
import { useLocalStore } from './LocalStore';
import { Background } from '../addons/Sde/Background.jsx'
import { MdContentPaste } from 'react-icons/md';


// # TS DEFINITIONS *******************************************

declare global {
  interface Window {
    JitsiMeetJS: any
  }
}

export type Track = {
  track:{id:string}
  containers:any[]
  getType: () => 'video'|'audio'
  dispose: () => void
  isLocal: () => boolean
  isMuted: () => boolean
  mute: () => void
  unmute: () => void
  addEventListener: (eventType:string,callback:(...rest)=>void) => boolean
  removeEventListener: (eventType:string,callback:(...rest)=>void) => boolean
  getParticipantId: () => ID
  attach: (element:HTMLElement) => void
  detach: (element:HTMLElement) => void
}
export type AudioTrack = Track
export type VideoTrack = Track 

export type User = {
  id:ID, user?:any, 
  mute:boolean, 
  volume:number, 
  muted: boolean, 
  flipped:boolean, 
  highlighted:boolean,
  highlighted_speaker: boolean,
  hidden: boolean,
  reply:string,
  status:string,
  pos:Point, audio?:AudioTrack, video?:VideoTrack }
  
type Users = { [id:string]:User }
type Point = {x:number, y:number}
type ID = string

export type IJitsiConference={
  on: (eventType:string,callback:(...rest)=>void) => boolean
  addCommandListener: (command:string,callback:(e:any)=>void) => boolean
  sendCommand: (command:string,payload:any) => boolean
  join:()=>void
  setDisplayName:(name:string)=>void
  addTrack:(track:Track)=>Promise<any>
  myUserId:()=>ID
  leave:()=>void
}

type ConferenceStore = {
  conferenceObject?: IJitsiConference
  conferenceName: string|undefined
  isJoined: boolean
  users: Users
  displayName:string
  error:any
  rules:any
  show_rules: boolean
  music_volume: number
} & ConferenceActions & UserActions

type ConferenceActions = {
  init: (conferenceID:string) => void
  join: () => void
  leave: () => void
  setConferenceName: (name:string) => boolean
  addToRules: (rule: string) => void
  showRules: (value:boolean) => void
}

type UserActions = {
  setDisplayName:(name:string)=>void
  calculateVolume: (id:ID) => void
  calculateVolumes: (localPos:Point) => void
}

// # IMPLEMENTATIONS *******************************************

export const useConferenceStore = create<ConferenceStore>((set,get) => {

  const initialState = {
    conferenceObject:undefined,
    conferenceName: process.env.REACT_APP_DEMO_SESSION || "chatmosphere",
    isJoined:false,
    users:{},
    displayName:"Dein Name",
    error:undefined,
    rules:[],
    show_rules: false,
    music_volume: 1
  }

  const produceAndSet = (callback:(newState:ConferenceStore)=>void)=>set(state => produce(state, newState => callback(newState)))

  // Private Helper Functions *******************************************
  const _addUser = (id:ID, user?:any) :void => produceAndSet (newState => {
    newState.users[id] = {id:id, user:user, mute:false, muted:false, flipped:false, hidden:false, highlighted:false, highlighted_speaker:false, status:"", reply:"", volume:1, pos:{x:0, y:0}}
  })
  const _removeUser = (id:ID) :void => produceAndSet (newState => {
    delete newState.users[id]
  })
  const _addAudioTrack = (id:ID, track:Track) => produceAndSet (newState => {
    if(newState.users[id]) 
    {
      newState.users[id].audio = track
      newState.users[id]['mute'] = track.isMuted()
    }
  })
  const _removeAudioTrack = (id:ID):void => produceAndSet (newState => {
    if(newState.users[id]) newState.users[id].audio = undefined
  })
  const _addVideoTrack = (id:ID, track:Track):void => produceAndSet (newState => {
    if(newState.users[id]) newState.users[id].video = track
  })
  const _removeVideoTrack = (id:ID):void => produceAndSet (newState => {
    if(newState.users[id]) newState.users[id].video = undefined
  })
  const _onPositionReceived = (e:any):void => {
    const pos = JSON.parse(e.value)
    _updateUserPosition(pos.id, {x:pos.x, y:pos.y})
  }
  const _updateUserPosition = (id:ID, pos:Point):void => produceAndSet (newState => {
    if(newState.users[id]) newState.users[id]['pos'] = pos
  })
  const _onTrackMuteChanged = (track:Track):void => {
    if(track.getType() === 'video') return
    const tmpID = track.getParticipantId()
    set(state => produce(state, newState => {
      if(newState.users[tmpID]) newState.users[tmpID]['mute'] = track.isMuted() //check in beginning sucks
    }))
  }

  const _onConferenceError = (e) => {
    const connection = useConnectionStore.getState().connection
    // console.log("tmpConnection:",get().connection)
    set({ conferenceObject: undefined, error:connection?.xmpp.lastErrorMsg })
  }

  const _onRemoteTrackAdded = (track:Track):void => {
    if(track.isLocal()) return // also run on your own tracks so exit
    const JitsiMeetJS = useConnectionStore.getState().jsMeet 
    track.addEventListener(JitsiMeetJS?.events.track.LOCAL_TRACK_STOPPED,() => console.log('remote track stopped'))
    track.addEventListener(JitsiMeetJS?.events.track.TRACK_AUDIO_OUTPUT_CHANGED,deviceId =>console.log(`track audio output device was changed to ${deviceId}`))
    const id = track.getParticipantId() // get user id of track
    track.getType() === "audio" ? _addAudioTrack(id, track) : _addVideoTrack(id, track)
  }
  const _onRemoteTrackRemoved = (track:Track):void => {
    // TODO: Remove track from user Object
    const id = track.getParticipantId() // get user id of track
    track.getType() === 'audio' ? _removeAudioTrack(id) : _removeVideoTrack(id) // do we need that? maybe if user is still there but closes video?
    track.dispose()
  }

  const _onConferenceJoined = () => {
    set({isJoined:true})//only Local User -> could be in LocalStore
    const conference = get().conferenceObject
    conference?.setDisplayName(get().displayName)
  } 


  
  //////////// Spiel dich erwachsen handler

  const addToRules = (rule: string) => {
    set(({ rules }) => {
      const nextRules = rules.concat(rule);
      return { rules: nextRules };
    });
  }

  const showRules = (value:boolean) =>produceAndSet (newState => {
    newState.show_rules = value
  })

  const setMusicVolume = (value:number) =>produceAndSet (newState => {
    value = Math.max(0,value)
    newState.music_volume = value
  })
  
  const _onTimerReceived = (e:any):void => {
    const content = e.attributes.content
    _updateTimer(content)
  }

  const _onMusicVolumeReceived = (e:any):void => {
    const content = e.attributes.content
    console.info("music_volume received", content);
    if (!isNaN(content)){setMusicVolume(content)}
  }

  const _updateTimer = (value:number):void => {
      console.info("timer received", value);
      useLocalStore.setState({countdownValue: value})
      useLocalStore.setState({hasCountdown: true})
  }

  const _onAbortTimerReceived = (e:any):void => {
    console.info("timer abort received");
    useLocalStore.setState({countdownValue: 0})
    useLocalStore.setState({hasCountdown: false})
  }

  const _onMsgReceived = (e:any):void => {
    const ids = e.attributes.ids
    const content = e.attributes.content
    console.info('message received: ', content)
    //! useref?
    // const conference = get().conferenceObject
    // const myId = conference?.myUserId()

    // if (myId){
    //   console.log("aaa", myId, ids)
    //   if (ids==myId){
    //     console.log ("found my own id")
    //   }
    //}

    useLocalStore.setState({message: content})
    useLocalStore.setState({hasMessage: true})    
  }

  const _onMsgToOneReceived = (e:any):void => {
    const ids = e.attributes.ids
    const content = e.attributes.content
    console.log('message to one received: ', content)
    //! useref?
    const conference = get().conferenceObject
    const myId = conference?.myUserId()

    if (myId){
      if (ids==myId){
        console.log ("found my own id")
        useLocalStore.setState({message: content})
        useLocalStore.setState({hasMessage: true})
      }
    }
  }

  const _onSoundReceived = (e:any):void => {
    const content = e.attributes.content
    console.log('sound received: ', content)
    useLocalStore.setState({currentSound: content})
  }
  const _onMusicReceived = (e:any):void => {
    const content = e.attributes.content
    const ids = e.attributes.ids
    setMusicVolume(1)
    console.log('music received: ', content)
    useLocalStore.setState({currentMusic: content})
  }
  const _onMusicStopReceived = (value:number):void => {
    console.log('music stop received')
    useLocalStore.setState({currentMusic: ""})
  }

  const _onSoundStopReceived = (value:number):void => {
    console.info('sound stop received')
    useLocalStore.setState({currentSound: ""})
  }

  const _onChatBoxReceived = (e:any):void => {
    const content = e.attributes.content
    const ids = e.attributes.ids

    console.info('chatbox received: ', content)
    useLocalStore.setState({hasChatbox: true})
  }

  const _onChatBoxSelectReceived = (e:any):void => {
    const ids = e.attributes.ids
    //! useref?
    const conference = get().conferenceObject
    const myId = conference?.myUserId()

    console.info('chatbox selected received: ')
    if (myId){
      if (ids==myId){
        console.info ("found my own id")
        useLocalStore.setState({hasChatbox: true})
      }
    }
  }

  const _onChatBoxStopReceived = (e:any):void => {
    const content = e.attributes.content
    const ids = e.attributes.ids

    console.info('chatbox stop received: ', content)
    useLocalStore.setState({hasChatbox: false})
  }

  const _onMuteAllReceived = (e:any):void => {
    console.info('mute-all received')
    useLocalStore.setState({mute:true})
    const audioTrack = useLocalStore.getState().audio
    if(!audioTrack) return
    audioTrack.mute()
  }

  const _onMuteReceived = (e:any):void => {
    const ids = e.attributes.ids
    //! useref?
    const conference = get().conferenceObject
    const myId = conference?.myUserId()

    console.info('mute received: ')
    if (myId){
      if (ids==myId){
        console.info ("found my own id")
        useLocalStore.setState({mute:true})
        const audioTrack = useLocalStore.getState().audio
        if(!audioTrack) return
        audioTrack.mute()
      }
    }
  }


  //!!! fixme
  const _onVideoflipReceived = (id:ID):void => produceAndSet (newState => {
    if(newState.users[id]) newState.users[id]['flipped'] = !newState.users[id]['flipped']
    console.info('_onVideoflipReceived')
    const conference = get().conferenceObject
    const myId = conference?.myUserId()
    if (myId){
      if (id==myId){
        console.info ("found my own id")
        useLocalStore.setState({flipped: !useLocalStore.getState().flipped })
      }
    }

  })

  const _onMsgFromUser = (e:any):void => {
    const msg = e.attributes.content
    const id = e.attributes.user_id

    console.info('message to from user received: ', msg, id)
    setUserReply(id, msg)
  }

  const setUserReply = (id:ID, msg:string):void => produceAndSet (newState => {
    if(newState.users[id]) newState.users[id]['reply'] = msg
  })

  const _onRedirectReceived = (e:any):void => {
      const content = e.attributes.content
      const ids = e.attributes.ids
      const conference = get().conferenceObject
      const myId = conference?.myUserId()
      console.info('_onRedirectReceived', content, ids)

      if (myId){
        if (ids==myId){
          console.info ("found my own id")

          //!! von jitsi abmelden
          window.location.href = content
        }
      }
  }

  const _onEndReceived = (e:any):void => {
    const content = e.attributes.content
    console.info('_onEndReceived', content)
 
    //!! von jitsi abmelden
    window.location.href = content
    
  }

  const _onRuleReceived = (e:any):void => {
    const content = e.attributes.content
    console.info('_onRuleReceived', content)
    addToRules(content);
  }

  const _onShowRulesReceived = (e:any):void => {
    console.info('_onShowRulesReceived')
    showRules(true);
  }

  const _onChangeBackgroundReceived = (e:any):void => {
    const content = e.attributes.content
    console.info('_onChangeBackgroundReceived', content)
    useLocalStore.setState({currentBackground: content})
  }

  const _onHighlightReceived = (e:any):void => {
    const id = e.attributes.ids
    console.info('_onHighlightReceived', id)
    produceAndSet (newState => {
        if(newState.users[id]) newState.users[id]['highlighted'] = true
    })
    const conference = get().conferenceObject
    const myId = conference?.myUserId()
    if (myId){
      if (id==myId){
        console.info ("found my own id")
        useLocalStore.setState({highlighted: true})
      }
    }

  }
  const _onHighlightSpeakerReceived = (e:any):void => {
    const id = e.attributes.ids
    console.info('_onHighlightSpeakerReceived', id)
    produceAndSet (newState => {
      if(newState.users[id]) newState.users[id]['highlighted_speaker'] = true
    })
    const conference = get().conferenceObject
    const myId = conference?.myUserId()
    if (myId){
      if (id==myId){
        console.info ("found my own id")
        useLocalStore.setState({highlighted_speaker: true})
      }
    }
  }

  const _onUnHighlightReceived = (e:any):void => {
    const id = e.attributes.ids
    console.info('_onUnHighlightReceived',  id)
    produceAndSet (newState => {
        if(newState.users[id]) newState.users[id]['highlighted'] = false
    })
    const conference = get().conferenceObject
    const myId = conference?.myUserId()
    if (myId){
      if (id==myId){
        console.info ("found my own id")
        useLocalStore.setState({highlighted: false})
      }
    }
  }
  const _onUnHighlightSpeakerReceived = (e:any):void => {
    const id = e.attributes.ids
    console.info('_onHighlightSpeakerReceived',  id)
    produceAndSet (newState => {
      if(newState.users[id]) newState.users[id]['highlighted_speaker'] = false
    })
    const conference = get().conferenceObject
    const myId = conference?.myUserId()
    if (myId){
      if (id==myId){
        console.info ("found my own id")
        useLocalStore.setState({highlighted_speaker: false})
      }
    }
  }

  const _onHiddenReceived = (e:any):void => {
    const id = e.attributes.ids
    console.info('_onHidden', id)
    produceAndSet (newState => {
      if(newState.users[id]) newState.users[id]['hidden'] = true
    })
  }
  const _onUnHiddenReceived = (e:any):void => {
    const id = e.attributes.ids
    console.info('_onUnHidden', id)
    produceAndSet (newState => {
      if(newState.users[id]) newState.users[id]['hidden'] = false
    })
  }
  const _onMusicFadedownReceived = (e:any):void => {
    console.info('_onMusicFadedownReceived')
    
    
    var fadeAudio = setInterval(function () {
        const currentMusicVolume = useConferenceStore.getState().music_volume
        // Only fade if past the fade out point or not at zero already
        //if ((sound.audio.currentTime >= fadePoint) && (sound.audio.volume >= 0.0)) {

        if (currentMusicVolume >=0.0){
            if (currentMusicVolume> 0.1)
              setMusicVolume(currentMusicVolume - 0.1)
            else 
              setMusicVolume(currentMusicVolume - 0.01);
        }
        
        // When volume at zero stop all the intervalling
        if (currentMusicVolume < 0.01) {
            console.log("done fading");
            useLocalStore.setState({currentMusic: ""})
            //sound.audio.pause();
            clearInterval(fadeAudio);
        }
    }, 300);
  
  }



    
  


  

  // # Public functions *******************************************
  const init = (conferenceID:string):void => {
    const JitsiMeetJS = useConnectionStore.getState().jsMeet 
    const connection = useConnectionStore.getState().connection //either move to ConnectionStore or handle undefined here
    const enteredConferenceName = conferenceID.length > 0 ? conferenceID.toLowerCase() : get().conferenceName?.toLowerCase()
    const conferenceName = process.env.REACT_APP_DEMO_SESSION || enteredConferenceName
    set({conferenceName:conferenceName})
    // console.log("init:",connection ,JitsiMeetJS , conferenceName,useConnectionStore.getState().connected,conferenceID)
    if(connection && JitsiMeetJS && conferenceName) {
      const conference = connection.initJitsiConference(conferenceName, conferenceOptions) //TODO before unload close connection
      conference.on(JitsiMeetJS.events.conference.USER_JOINED, _addUser)
      conference.on(JitsiMeetJS.events.conference.USER_LEFT, _removeUser)
      conference.on(JitsiMeetJS.events.conference.TRACK_ADDED, _onRemoteTrackAdded)
      conference.on(JitsiMeetJS.events.conference.TRACK_REMOVED, _onRemoteTrackRemoved)
      conference.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, _onConferenceJoined)
      conference.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, _onTrackMuteChanged);
      conference.on(JitsiMeetJS.events.conference.CONFERENCE_ERROR, _onConferenceError);
      //conference.on(JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED, onUserNameChanged);
      // conference.on(JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED, on_remote_track_audio_level_changed);

      conference.addCommandListener("pos", _onPositionReceived)
      
      ////// Spiel dich erwachsenen Listener
      conference.addCommandListener("timer", (data) => {_onTimerReceived(data)})
      conference.addCommandListener("abort_timer", (data) => { _onAbortTimerReceived(data)})      
      conference.addCommandListener("msg-to-all", (data) => {_onMsgReceived(data)})
      conference.addCommandListener("msg-to-one", (data) => {_onMsgToOneReceived(data)})
      conference.addCommandListener("sound", (data) => { _onSoundReceived(data)})
      conference.addCommandListener("music", (data) => { _onMusicReceived(data)})
      conference.addCommandListener("fade_out_music", (data) => { _onMusicFadedownReceived(data)})
      conference.addCommandListener("music_stop", _onMusicStopReceived)
      conference.addCommandListener("music_volume",  (data) => { _onMusicVolumeReceived(data)})
      conference.addCommandListener("sound_stop", _onSoundStopReceived)
      conference.addCommandListener("chat-start-selects", (data) => { _onChatBoxSelectReceived(data)})
      conference.addCommandListener("chat-start-all", (data) => { _onChatBoxReceived(data)})
      conference.addCommandListener("chat-stop", (data) => { _onChatBoxStopReceived(data)})
      conference.addCommandListener("mute-all", (data) => { _onMuteAllReceived(data)})
      conference.addCommandListener("mute", (data) => { _onMuteReceived(data)})
      conference.addCommandListener("video_flip", (data) => { _onVideoflipReceived(data)})
      conference.addCommandListener("msg_from_user", (data) => { _onMsgFromUser(data)})
      conference.addCommandListener("redirect", (data) => { _onRedirectReceived(data)})
      conference.addCommandListener("pen_on", (data) => { _onHighlightReceived(data)})
      conference.addCommandListener("mic_on", (data) => { _onHighlightSpeakerReceived(data)})
      conference.addCommandListener("pen_off", (data) => { _onUnHighlightReceived(data)})
      conference.addCommandListener("mic_off", (data) => { _onUnHighlightSpeakerReceived(data)})
      conference.addCommandListener("hide", (data) => { _onHiddenReceived(data)})
      conference.addCommandListener("un_hide", (data) => { _onUnHiddenReceived(data)})
      conference.addCommandListener("add_rule", (data) => { _onRuleReceived(data)})
      conference.addCommandListener("show_rules", (data) => { _onShowRulesReceived(data)})
      conference.addCommandListener("show_rules", (data) => { _onShowRulesReceived(data)})
      conference.addCommandListener("change_background", (data) => { _onChangeBackgroundReceived(data)})

      ////////////////
      // r.on(JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED, (e) => console.log("Property Changed ", e))
      window.addEventListener('beforeunload', leave) //does this help?  
      window.addEventListener('unload', leave) //does this help?
      conference.join()
      set({conferenceObject:conference,error:undefined})
    } else {
      throw new Error('Jitsi Server connection has not been initialized or failed :( - did you call initJitsiMeet on ConnectionStore yet?')
    }
  }
  const join = () => {
  }
  const leave = () => { 
    const conference = get().conferenceObject
    conference?.leave()
  }
  const setConferenceName = (name) => {
    if(name.length < 1) return false
    const lName:string = name.toLowerCase()
    set({conferenceName:lName})
    return true
  }
  //?!
  const setDisplayName = (name) => {
    set({displayName:name})
    const conference = get().conferenceObject
    conference?.setDisplayName(name)
  }
  const calculateVolume = (id:ID):void => produceAndSet (newState => {
    const localUserPosition:Point = useLocalStore.getState().pos //check if this is updated or kept by closure
    newState.users[id]['volume'] = getVolumeByDistance(localUserPosition, newState.users[id]['pos'])
  })
  const calculateVolumes = (localPos:Point) => produceAndSet (newState => {
    const users = newState.users
    Object.keys(users).map(key => {
      const user = users[key]
      newState.users[key]['volume'] = getVolumeByDistance(localPos, user.pos)
      return null
    })
  })

  // Return Object *******************************************
  return {
    ...initialState,
    init,
    join,
    leave,
    setConferenceName,
    setDisplayName,
    calculateVolume,
    calculateVolumes,
    addToRules,
    showRules,
    setMusicVolume
  }
})

if(process.env.NODE_ENV === 'development') {
	mountStoreDevtool('ConferenceStore', useConferenceStore)
}