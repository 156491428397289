import React from 'react'
import JitsiConnection from '../../components/JitsiConnection/JitsiConnection'
import { useConferenceStore } from "../../store/ConferenceStore"
import { useConnectionStore } from "../../store/ConnectionStore"
import shallow from 'zustand/shallow'
import { useState, useRef, useEffect } from "react"
import styled from 'styled-components'
import './Schaltzentrale.css'

import { Track, User, ConferenceStore } from "../../store/ConferenceStore";

import { Room } from '../../components/Room/Room'
import { Users } from '../../components/User/Users'
import scenes_json from './assets/Skriptablauf.json';

const Admin = styled.div`
  position:relative;
  text-align: left;
  overflow-y: scroll;
  height: 100%;
`

export const Titel = (props)=>
{
  const TitelStyle={
    fontSize: '25pt',
    marginLeft: '15px'
  }
  return (<div style={TitelStyle}>{props.children}</div>)
}

const ScenesDiv = styled.div`
  position:relative;
  top:100px;
`
export const Scenes = (data) => {

//{JSON.stringify({ url, isActive }, null, 2)}

  //json preparation
  let prepared_scenes = []
  let current_scene = []
  let prior_titel = false
  Object.entries(data[Object.keys(data)[0]].Skriptablauf).map((entry) => {
      //skip empty rows
      if (entry[1].Titel) {
        // init first titel
        if (!prior_titel) prior_titel = entry[1].Titel
        if (entry[1].Titel == prior_titel){
          current_scene.push(entry[1])
        }else{
          prepared_scenes.push ({key:prior_titel, value: current_scene})
          current_scene = []
          current_scene.push(entry[1])
        }
        prior_titel = entry[1].Titel
        }
      }
      )
      // push remaining scenes onto prepared
      prepared_scenes.push ({key:prior_titel, value: current_scene})
  return (    
        <ScenesDiv>
        {Object.entries(prepared_scenes).map((Ablauf,i) => (
          <div key={i}>
                <Titel>{Ablauf[1].key}</Titel>
                <SceneBlock SceneBlock={Ablauf[1].value} />
          </div>
          ))
        }
        </ScenesDiv>
    )}

const OrderButtonDiv = styled.div`
  margin-left:40px;
  margin-bottom: 10px;
  flex: 0 0 300px;
`

const Button = styled.button`
  border: 1px solid transparent;
  border-radius: 3px;
  padding:5px;
  transition: all 0.1s;
  &:hover {
    color: white;
  }
`

export const OrderButton = ({cmd, val, style})=>{
  const [selected,setSelect]= useState();
  const [clicked, setClicked] = useState(true);

  const handleDropdownChange = (e) => {
    setSelect(e.target.value);
  }

  const OrderAction = () => {
    setClicked(false);
  
    const conference = useConferenceStore.getState().conferenceObject

    conference?.sendCommandOnce(cmd, { value: 0, attributes: {
        ids: selected,
        content: val
         }
    })
  }

  let OrderButtonColor={
      backgroundColor: "#4CAF50"
  }

  if (cmd == "sound"){
    val = val.slice(0, -4)
    OrderButtonColor={backgroundColor: "#39ca74"}
  }
  else if (cmd == "music"){
    val = val.slice(0, -4)
    OrderButtonColor={backgroundColor: "#fed86f"}
  }
  else if (cmd == "music_stop")
    {OrderButtonColor={backgroundColor: "#aef86f"}}
  
  else if (cmd == "fade_out_music")
    { OrderButtonColor={backgroundColor: "#fed11f"}}

  else if (cmd == "chat-stop" || cmd == "chat-start-all")
    {OrderButtonColor={backgroundColor: "#fef16f"}}
  
  else if (cmd == "timer")
    {OrderButtonColor={backgroundColor: "#de6768"}}
  
  else if (cmd == "mic_on"|| "mic_off")
    {OrderButtonColor={backgroundColor: "#aeaa68"}}

  else if (cmd == "pen_on"|| "pen_off")
    { OrderButtonColor={backgroundColor: "#2ef168"}}
    


  if (cmd == "msg-to-one" || cmd == "chat-start-selects" || 
      cmd == "mic_off" || cmd == "mic_on" ||
      cmd == "pen_off" || cmd == "pen_on" || cmd == "video_flip"
    ){

      OrderButtonColor={backgroundColor: "#6ec1a8"}

    return (
      <OrderButtonDiv>
      <select style={{float:"left"}} id="userlist" onChange={handleDropdownChange}>
      <UserOptions/>
      </select>
      <Button style={OrderButtonColor} className={clicked ? "" : "btnClicked"} onClick={OrderAction}>{cmd} - {val} - {selected} </Button>
      </OrderButtonDiv>
      )
  }


  else if (cmd == "add_new_rule") {
    return (
      <OrderButtonDiv><AddRule/></OrderButtonDiv>)
  }
  
  else if (cmd == "new_msg_to_all") {
    return (
      <OrderButtonDiv><MessengerAll/></OrderButtonDiv>)
  }

  else{
    return (
      <OrderButtonDiv><Button style={OrderButtonColor} className={clicked ? "" : "btnClicked"} onClick={e => OrderAction({cmd,val})}>{cmd} - {val}</Button></OrderButtonDiv>)
  }
}



export const DirectOrderButton = ({description, cmd, val, id, style})=>{
  const [clicked, setClicked] = useState(true);

  const OrderAction = () => {
    setClicked(false);
    const conference = useConferenceStore.getState().conferenceObject
    conference?.sendCommandOnce(cmd, { value: 0, attributes: {
        ids: id,
        content: val
         }
    })
  }

  let OrderButtonColor={
      backgroundColor: "#1CAA50"
  }
  return (
      <Button style={OrderButtonColor} className={clicked ? "" : "btnClicked"} onClick={e => OrderAction({cmd,val,id})}>{description}</Button>)
  
}


const Minitext = styled.div`
  margin-left: 10px;
  font-size: 6pt;
`

const OrderBlock = styled.div`
 display: flex; 
`

const Comment = styled.div`
 margin-left:25px;
 font-style: italic;
`

export const Order = ({orders}) =>(
  <>
    {orders.map((single_order, i)=>{
        if (single_order.Befehl === "comment"){
          return (<Comment key={i}>{single_order.Inhalt}</Comment>)
        }
        else{
          let short_text = ""
          if (single_order.Script_Text)
            if (single_order.Script_Text.length > 200)
              short_text = single_order.Script_Text.substr(0,100)+ " [...] " + single_order.Script_Text.substr(-100)
            else
              short_text = single_order.Script_Text
          return (<OrderBlock key={i}><OrderButton key={i} cmd={single_order.Befehl} val={single_order.Inhalt} />
            <Minitext title={single_order.Script_Text}>{short_text}</Minitext>
            </OrderBlock>)
        }
      })
    }
  </>
)

        
export const SceneBlock = (SceneBlock) => {
  return (
    <>
    {Object.entries(SceneBlock).map((order, i)=>(
     <Order key={i} orders={order[1]} />
    ))}
    </>
  )
}

// export const Timer_button = () => {
//   const conference = useConferenceStore.getState().conferenceObject
//   conference?.sendCommandOnce("timer", { value: 12, attributes: {
//       ids: ["123123","123123", "asda"] }
//     })
//   return null
// }

export const Redirect = () => {
  const conference = useConferenceStore.getState().conferenceObject
  conference?.sendCommandOnce("redirect", { value: 12 , attributes: {
      ids: ["123123","282d1d9d", "82621444"]
    }
  })
  return null
}

export const BackgroundChange = () => {

  const conference = useConferenceStore.getState().conferenceObject
  conference?.sendCommandOnce("background", { value: 12 , attributes: {
      ids: ["123123","282d1d9d", "82621444"]
    }
  })
  return null
}

export const MuteAll = () => {
  const conference = useConferenceStore.getState().conferenceObject
  conference?.sendCommandOnce("mute-all", { value: 0})
  return null
}

export const MusicStop = () => {
  const conference = useConferenceStore.getState().conferenceObject
  conference?.sendCommandOnce("music_stop", { value: 0})
  return null
}

export const SoundStop = () => {
  const conference = useConferenceStore.getState().conferenceObject
  conference?.sendCommandOnce("sound_stop", { value: 0})
  return null
}
export const AbortTimer = () => {
  const conference = useConferenceStore.getState().conferenceObject
  conference?.sendCommandOnce("abort_timer", { value: 0})
  return null
}

export const user_button = () => {
  const conference = useConferenceStore.getState().conferenceObject
  conference?.sendCommandOnce("msg", { value: 12 , attributes: {
      ids: ["123123","282d1d9d", "82621444"]
    }
  })
  return null
}

export const fade_out_music = () => {
  const conference = useConferenceStore.getState().conferenceObject
  conference?.sendCommandOnce("fade_out_music", { value: 0})
  return null
}


export const UserBox = (props) => {
  const [clicked, setClicked] = useState(true);

  const Action = () => {
    setClicked(!clicked);
  }

  return (
    <>
    <span onClick={e => Action()}>⬇️</span>

      <div className={clicked ? "unfolded" : "folded"}>
      {props.children}
      </div>
    </>
)
}


export const UserOptions = () => {
  const users = useConferenceStore(state => state.users) //.getState().users
  const [user_list, setUser_list] = useState([]);
  let u = []

  useEffect(()=> {
        Object.keys(users).forEach((key) => {
          u.push({id:users[key].id, name:users[key].user._displayName, msg:users[key].user.answer})
        })
        setUser_list(u)
        },[users])

    return (
      <>
        <option />
      {user_list.map((user) => (
        <option value={user.id}>{user.name}</option>
        ))
      }
      </>
  )
}
const UserCollumn= styled.div`
width: 800px;
display:flex;
`
const UserCollumnButtons = styled.div`
  flex: 1 0 auto;
`
const UserCollumnName = styled.div`
  width: 200px;
  flex: 0 0 200px;
`
const UserCollumnStatus = styled.div`
  width: 200px;
  flex: 0 0 200px;
  line-break: anywhere;
`
const UserCollumnReply = styled.div`
  flex: 0 0 400px;
  line-break: anywhere;
`

export const UserList = () => {
  
  
  // const { users_id, users_name, users_reply, users_status } = useConferenceStore(state =>
  //    ({ users_id: state.users.id, 
  //     users_name: state.users._displayName, 
  //     users_reply:state.users.reply, 
  //     users_status:state.users.status }), shallow)

  const users = useConferenceStore(state => state.users)
  //const users = useConferenceStore(state => Object.keys(state.users._displayName), shallow)

  const [user_list, setUser_list] = useState([]);
  let u = []

  useEffect(()=> {
    //const users = useConferenceStore.getState().users
        Object.keys(users).forEach((key) => {
          u.push({id:users[key].id, name:users[key].user._displayName, reply:users[key].reply})
        })
        setUser_list(u)
        },[users])

    return (
      <>
        {user_list.map((user) => (
          <UserCollumn>
            <UserCollumnButtons>
              <DirectOrderButton key="pen_on" description="pen_on" cmd="pen_on" id={user.id} />
              <DirectOrderButton key="pen_off" description="pen_off" cmd="pen_off" id={user.id} />
              <DirectOrderButton key="mic_on" description="mic_on" cmd="mic_on" id={user.id} />
              <DirectOrderButton key="mic_off" description="mic_off" cmd="mic_off" id={user.id} />
              <DirectOrderButton key="hide" description="hide" cmd="hide" id={user.id} />
              <DirectOrderButton key="un_hide" description="un_hide" cmd="un_hide" id={user.id} />
              <DirectOrderButton key="video_flip" description="video_flip" cmd="video_flip" id={user.id} />
              <DirectOrderButton key="mute" description="mute" cmd="mute" id={user.id} />
              <DirectOrderButton key="funktioniert_nicht" description="funktioniert_nicht" cmd="redirect" val="http://konferenz.pulk-fiktion.de/funktioniert_nicht" id={user.id} />
            </UserCollumnButtons>
            <UserCollumnName><b>{user.name}</b><small>({user.id})</small></UserCollumnName>
            {/* <UserCollumnStatus>{user.status}</UserCollumnStatus> */}
            <UserCollumnReply>{user.reply}</UserCollumnReply>
          </UserCollumn>

        ))
        }
      </>
  )
}

export const AddRule = () =>{
  const [formInput,setformInput]= useState();
  
  const handleInputChange = (e) => {
    setformInput(e.target.value);
  }  

  const RuleAction = () => {
    console.log(formInput)
    const conference = useConferenceStore.getState().conferenceObject
    conference?.sendCommandOnce("add_rule", { value: 0, attributes: {
        content: formInput
         }
    })
  }
  return(
    <>
    <input type="text" size="80" value={formInput} onChange={handleInputChange} ></input>
    <button onClick={RuleAction}>Add Rule</button>
    </>
  )
}

export const VolumeControl = () =>{
  const [formInput,setformInput]= useState();

  const handleInputChange = (e) => {
    setformInput(e.target.value);

    const conference = useConferenceStore.getState().conferenceObject
    conference?.sendCommandOnce("music_volume", { value: 0, attributes: {
        content: formInput / 100
         }
    })
  }  
  return(
    <>
    <input type="range" id="volume" name="volume" min="0" max="100" defaultValue="100"
          onChange={handleInputChange}
        />
    </>
  )
}

export const MessengerAll = () =>{

  const [formInput,setformInput]= useState();
  
  const handleInputChange = (e) => {
    setformInput(e.target.value);
  }  

  const MessengerAction = () => {
    const conference = useConferenceStore.getState().conferenceObject
    setformInput('')
    conference?.sendCommandOnce("msg-to-all", { value: 0, attributes: {
        content: formInput
         }
    })
  }

  return(
    <>
    <input type="text" size="80" value={formInput} onChange={handleInputChange} ></input>
    <button onClick={MessengerAction}>Msg to All</button>
    </>
  )
}


export const MessengerOne = () =>{
  const [selected,setSelect]= useState();
  const [formInput,setformInput]= useState();
  
  const handleInputChange = (e) => {
    setformInput(e.target.value);
  }  

  const handleDropdownChange = (e) => {
    setSelect(e.target.value);
  }

  const MessengerAction = () => {
    const conference = useConferenceStore.getState().conferenceObject
    setformInput('')
    conference?.sendCommandOnce("msg-to-one", { value: 0, attributes: {
        ids: selected,
        content: formInput
         }
    })
  }

  return(
    <>
      <input type="text" size="80" value={formInput} onChange={handleInputChange} ></input>
      <select id="userlist" onChange={handleDropdownChange}>
      <UserOptions/>
      </select>
      <button onClick={MessengerAction}>msg to {selected} </button>
    </>
  )
}



const AdminTopBarDiv = styled.div`
  position:fixed;
  background-color: #f7d99e;
  max-height: 400px;
  width:100%;
  z-index: 10;
  overflow: auto;
`

export const AdminTopBar = () => {
    return(
      <AdminTopBarDiv>
      <button onClick={MusicStop}>Stop Music</button>
      <button onClick={SoundStop}>Stop Sound</button>
      <button onClick={MuteAll}>mute all</button>
      <button onClick={AbortTimer}>abort timer</button>
      {/* <button onClick={Redirect}>redirect</button>
      <button onClick={BackgroundChange}>background</button> */}
      <button onClick={fade_out_music}>fade_out_music</button>
      <DirectOrderButton key="ende" cmd="ende" val="../../ende/" />
      <MessengerAll/>
      <VolumeControl/>
      <br/>
      <AddRule/>
      <MessengerOne />
      <UserWrap>
      <UserBox>
        <UserList/>
      </UserBox>
      </UserWrap>  
      
      </AdminTopBarDiv>
    )
  }

const UserWrap = styled.div`
  position: relative;
  font-size:10pt;
  left:200px;
  display:flex;
  flex-direction:column;
  padding-bottom: 10px;
`

export const Schaltzentrale = (id) => {
  useEffect(()=> { document.title = "Schaltzentrale"; })

  return(
    <Admin>
    <AdminTopBar />

    <div style={{marginTop:"500px"}}>
   <Scenes data={scenes_json} />
    </div>
          <JitsiConnection id={id}/>
    
    </Admin>
  )
}