import e01_erklaerung_einloggen from "./assets/sounds/e01_erklaerung_einloggen.mp3"
import e02_frage_prinzip_verstanden from "./assets/sounds/e02_frage_prinzip_verstanden.mp3"
import e02_opt_prinzip_nicht_verstanden from "./assets/sounds/e02_opt_prinzip_nicht_verstanden.mp3"
import e03_frage_gerne_erwachsen from "./assets/sounds/e03_frage_gerne_erwachsen.mp3"
import e04_frage_wegen_erwachsenen_hier from "./assets/sounds/e04_frage_wegen_erwachsenen_hier.mp3"
import e03_viel_oder_wenig_erlaubt from "./assets/sounds/e03_viel_oder_wenig_erlaubt.mp3"
import e05_frage_hier_bestimmen from "./assets/sounds/e05_frage_hier_bestimmen.mp3"
import e06_kinder_ohne_erwachsene from "./assets/sounds/e06_kinder_ohne_erwachsene.mp3"
import e07_wieder_zusammenkommen from "./assets/sounds/e07_wieder_zusammenkommen.mp3"
import el7_neu_laden from "./assets/sounds/el7_neu_laden.mp3"
import e10_frage_was_gerne_tun from "./assets/sounds/e10_frage_was_gerne_tun.mp3"
import e10_jetzt_umzug from "./assets/sounds/e10_jetzt_umzug.mp3"
import e11_vorstellung from "./assets/sounds/e11_vorstellung.mp3"
import e11_opt_alle_vorgestellt from "./assets/sounds/e11_opt_alle_vorgestellt.mp3"
import el1_schoen_dass_du_da_bist from "./assets/sounds/el1_schoen_dass_du_da_bist.mp3"
import el2_testen from "./assets/sounds/el2_testen.mp3"
import el3_warten_noch from "./assets/sounds/el3_warten_noch.mp3"
import el4_zeit_nutzen from "./assets/sounds/el4_zeit_nutzen.mp3"
import el5_geduld_lösen from "./assets/sounds/el5_geduld_lösen.mp3"
import el6_kopfhoerer_benutzen from "./assets/sounds/el6_kopfhoerer_benutzen.mp3"
import el8_im_Raum_bewegen from "./assets/sounds/el8_im_Raum_bewegen.mp3"
import el9_gebt_euch_namen from "./assets/sounds/el9_gebt_euch_namen.mp3"
import el9_geduld_geht_gleich_los from "./assets/sounds/el9_geduld_geht_gleich_los.mp3"
import el_11_zoomen from "./assets/sounds/el_11_zoomen.mp3"
import g0_frage_erwachsene_in_der_naehe from "./assets/sounds/g0_frage_erwachsene_in_der_naehe.mp3"
import g1_alt_gesten_trotz_freisprechen from "./assets/sounds/g1_alt_gesten_trotz_freisprechen.mp3"
import g1_gesten_nicht_freisprechen from "./assets/sounds/g1_gesten_nicht_freisprechen.mp3"
import g1_wir_jetzt_erwachsene_unsere_regeln from "./assets/sounds/g1_wir_jetzt_erwachsene_unsere_regeln.mp3"
import g2_lauter from "./assets/sounds/g2_lauter.mp3"
import g2_opt_macht_die_geste_gemeinsam from "./assets/sounds/g2_opt_macht_die_geste_gemeinsam.mp3"
import g3_geste1 from "./assets/sounds/g3_geste1.mp3"
import g3_schon_besser from "./assets/sounds/g3_schon_besser.mp3"
import g4_gegenstand_suchen from "./assets/sounds/g4_gegenstand_suchen.mp3"
import g4_geste2 from "./assets/sounds/g4_geste2.mp3"
import g5_gegenstand_verstellen from "./assets/sounds/g5_gegenstand_verstellen.mp3"
import g5_geste3 from "./assets/sounds/g5_geste3.mp3"
import g6_geste_jubel from "./assets/sounds/g6_geste_jubel.mp3"
import nr10_neue_regeln_abschlussgedanken from "./assets/sounds/nr10_neue_regeln_abschlussgedanken.mp3"
import nr11_Eure_Regeln from "./assets/sounds/nr11_Eure_Regeln.mp3"
import nr8_wenn_fertig_jubel from "./assets/sounds/nr8_wenn_fertig_jubel.mp3"
import p1_abschlussprotokoll_anweisung from "./assets/sounds/p1_abschlussprotokoll_anweisung.mp3"
import p2_zettel_screenshot from "./assets/sounds/p2_zettel_screenshot.mp3"
import p3_konferenz_ende_V1 from "./assets/sounds/p3_konferenz_ende_V1.mp3"
import p3_konferenz_ende_V2 from "./assets/sounds/p3_konferenz_ende_V2.mp3"
import r1_frage_warum_erwachsen_cool from "./assets/sounds/r1_frage_warum_erwachsen_cool.mp3"
import r2_regeln_gruppen from "./assets/sounds/r2_regeln_gruppen.mp3"
import r3_regeln_check_in_gesten from "./assets/sounds/r3_regeln_check_in_gesten.mp3"
import r4_regeln_anleitung from "./assets/sounds/r4_regeln_anleitung.mp3"
import r5_regeln_fertig_jetzt_neue from "./assets/sounds/r5_regeln_fertig_jetzt_neue.mp3"
import r6_zurück_in_die_Mitte from "./assets/sounds/r6_zurück_in_die_Mitte.mp3"
import r6_opt2_wie_stimmung_veraendern from "./assets/sounds/r6_opt2_wie_stimmung_veraendern.mp3"
import r6_opt3_spontan_nicht_zu_erwachsen from "./assets/sounds/r6_opt3_spontan_nicht_zu_erwachsen.mp3"
import r6_opt_nicht_zu_brav from "./assets/sounds/r6_opt_nicht_zu_brav.mp3"
import r7_neue_regeln_gruppendiskussion from "./assets/sounds/r7_neue_regeln_gruppendiskussion.mp3"
import r9_option_kommt_zum_ende from "./assets/sounds/r9_option_kommt_zum_ende.mp3"
import r_Regel_1 from "./assets/sounds/r_Regel_1.mp3"
import r_Regel_2 from "./assets/sounds/r_Regel_2.mp3"
import r_Regel_3 from "./assets/sounds/r_Regel_3.mp3"
import r_Regel_4 from "./assets/sounds/r_Regel_4.mp3"
import r_Regel_5 from "./assets/sounds/r_Regel_5.mp3"
import r_abgelehnt from "./assets/sounds/r_abgelehnt.mp3"
import r_jetzt_entscheidung from "./assets/sounds/r_jetzt_entscheidung.mp3"
import r_zugestimmt from "./assets/sounds/r_zugestimmt.mp3"
import u1_muss_man_auf_stuhl_sitzen from "./assets/sounds/u1_muss_man_auf_stuhl_sitzen.mp3"
import v1_kleidungsstueck from "./assets/sounds/v1_kleidungsstueck.mp3"
import v1_verantwortung_nachdenken from "./assets/sounds/v1_verantwortung_nachdenken.mp3"
import v2_regeln_zettel from "./assets/sounds/v2_regeln_zettel.mp3"
import v2_verantwortung_diskussion from "./assets/sounds/v2_verantwortung_diskussion.mp3"
import v3_gemeinsame_aktion_ueberlegen from "./assets/sounds/v3_gemeinsame_aktion_ueberlegen.mp3"
import v4_wohnraum_handeln_los from "./assets/sounds/v4_wohnraum_handeln_los.mp3"
import v4_zurück_in_Gruppe from "./assets/sounds/v4_zurück_in_Gruppe.mp3"
import v5_runterzaehlen from "./assets/sounds/v5_runterzaehlen.mp3"
import v6_glueckwunsch_handeln from "./assets/sounds/v6_glueckwunsch_handeln.mp3"
import z_hoeren from "./assets/sounds/z_hoeren.mp3"
import w1_willkommen from "./assets/sounds/w1_willkommen.mp3"
import w2_konferenz_eroeffnet from "./assets/sounds/w2_konferenz_eroeffnet.mp3"
import z_opt_redezeit_schenken from "./assets/sounds/z_opt_redezeit_schenken.mp3"
import z_opt_restliche_redezeit_schenken from "./assets/sounds/z_opt_restliche_redezeit_schenken.mp3"
import z_wer_am_meisten_geredet_hat from "./assets/sounds/z_wer_am_meisten_geredet_hat.mp3"
import FX2_Time_Out_1 from "./assets/sounds/FX2_Time_Out_1.mp3"
import FX1_Login1 from "./assets/sounds/FX1_Login1.mp3"
import e03b_wie_erwachsen from "./assets/sounds/e03b_wie_erwachsen.mp3"
import e11_systemfehler from "./assets/sounds/e11_systemfehler.mp3"
import e11_zuzwinkern from "./assets/sounds/e11_zuzwinkern.mp3"
import el12_anweisungen from "./assets/sounds/el12_anweisungen.mp3"
import el13_hören from "./assets/sounds/el13_hören.mp3"
import et1_tagesordnung_selbstversorgung from "./assets/sounds/et1_tagesordnung_selbstversorgung.mp3"
import g6_opt_große_Geste from "./assets/sounds/g6_opt_große_Geste.mp3"
import g7_opt_große_Geste from "./assets/sounds/g7_opt_große_Geste.mp3"
import g8_opt_sprechen_dürfen from "./assets/sounds/g8_opt_sprechen_dürfen.mp3"
import ra_zugestimmt from "./assets/sounds/ra_zugestimmt.mp3"
import rb_abgelehnt from "./assets/sounds/rb_abgelehnt.mp3"
import v3_Zettel_zeigen from "./assets/sounds/v3_Zettel_zeigen.mp3"
import v4_Kleidung_warum from "./assets/sounds/v4_Kleidung_warum.mp3"
import v7_abschluss_jubel from "./assets/sounds/v7_abschluss_jubel.mp3"
import z_reden_fragen_1 from "./assets/sounds/z_reden_fragen_1.mp3"
import z_reden_fragen_2 from "./assets/sounds/z_reden_fragen_2.mp3"
import z_was_denkst_du from "./assets/sounds/z_was_denkst_du.mp3"
import gong from "./assets/sounds/gong.mp3"
import FX2_Screenshot_Sound from "./assets/sounds/FX2_Screenshot_Sound.mp3"

const SOUNDS = {
"e01_erklaerung_einloggen": e01_erklaerung_einloggen,
"e02_frage_prinzip_verstanden": e02_frage_prinzip_verstanden,
"e02_opt_prinzip_nicht_verstanden": e02_opt_prinzip_nicht_verstanden,
"e03_frage_gerne_erwachsen": e03_frage_gerne_erwachsen,
"e03_viel_oder_wenig_erlaubt": e03_viel_oder_wenig_erlaubt,
"e04_frage_wegen_erwachsenen_hier": e04_frage_wegen_erwachsenen_hier,
"e05_frage_hier_bestimmen": e05_frage_hier_bestimmen,
"e06_kinder_ohne_erwachsene": e06_kinder_ohne_erwachsene,
"e07_wieder_zusammenkommen": e07_wieder_zusammenkommen,
"e10_frage_was_gerne_tun": e10_frage_was_gerne_tun,
"e10_jetzt_umzug": e10_jetzt_umzug,
"e11_opt_alle_vorgestellt": e11_opt_alle_vorgestellt,
"e11_vorstellung": e11_vorstellung,
"el1_schoen_dass_du_da_bist": el1_schoen_dass_du_da_bist,
"el2_testen": el2_testen,
"el3_warten_noch": el3_warten_noch,
"el4_zeit_nutzen": el4_zeit_nutzen,
"el5_geduld_lösen":el5_geduld_lösen,
"el6_kopfhoerer_benutzen": el6_kopfhoerer_benutzen,
"el7_neu_laden": el7_neu_laden,
"el8_im_Raum_bewegen": el8_im_Raum_bewegen,
"el9_gebt_euch_namen": el9_gebt_euch_namen,
"el9_geduld_geht_gleich_los": el9_geduld_geht_gleich_los,
"el_11_zoomen": el_11_zoomen,
"g0_frage_erwachsene_in_der_naehe": g0_frage_erwachsene_in_der_naehe,
"g1_alt_gesten_trotz_freisprechen": g1_alt_gesten_trotz_freisprechen,
"g1_gesten_nicht_freisprechen": g1_gesten_nicht_freisprechen,
"g1_wir_jetzt_erwachsene_unsere_regeln": g1_wir_jetzt_erwachsene_unsere_regeln,
"g2_lauter": g2_lauter,
"g2_opt_macht_die_geste_gemeinsam": g2_opt_macht_die_geste_gemeinsam,
"g3_geste1": g3_geste1,
"g3_schon_besser": g3_schon_besser,
"g4_gegenstand_suchen": g4_gegenstand_suchen,
"g4_geste2": g4_geste2,
"g5_gegenstand_verstellen": g5_gegenstand_verstellen,
"g5_geste3": g5_geste3,
"g6_geste_jubel": g6_geste_jubel,
"nr10_neue_regeln_abschlussgedanken": nr10_neue_regeln_abschlussgedanken,
"nr11_Eure_Regeln": nr11_Eure_Regeln,
"nr8_wenn_fertig_jubel": nr8_wenn_fertig_jubel,
"p1_abschlussprotokoll_anweisung": p1_abschlussprotokoll_anweisung,
"p2_zettel_screenshot": p2_zettel_screenshot,
"p3_konferenz_ende_V1": p3_konferenz_ende_V1,
"p3_konferenz_ende_V2": p3_konferenz_ende_V2,
"r1_frage_warum_erwachsen_cool": r1_frage_warum_erwachsen_cool,
"r2_regeln_gruppen": r2_regeln_gruppen,
"r3_regeln_check_in_gesten": r3_regeln_check_in_gesten,
"r4_regeln_anleitung": r4_regeln_anleitung,
"r5_regeln_fertig_jetzt_neue": r5_regeln_fertig_jetzt_neue,
"r6_zurück_in_die_Mitte": r6_zurück_in_die_Mitte,
"r6_opt2_wie_stimmung_veraendern": r6_opt2_wie_stimmung_veraendern,
"r6_opt3_spontan_nicht_zu_erwachsen": r6_opt3_spontan_nicht_zu_erwachsen,
"r6_opt_nicht_zu_brav": r6_opt_nicht_zu_brav,
"r7_neue_regeln_gruppendiskussion": r7_neue_regeln_gruppendiskussion,
"r9_option_kommt_zum_ende": r9_option_kommt_zum_ende,
"r_Regel_1": r_Regel_1,
"r_Regel_2": r_Regel_2,
"r_Regel_3": r_Regel_3,
"r_Regel_4": r_Regel_4,
"r_Regel_5": r_Regel_5,
"r_abgelehnt": r_abgelehnt,
"r_jetzt_entscheidung": r_jetzt_entscheidung,
"r_zugestimmt": r_zugestimmt,
"u1_muss_man_auf_stuhl_sitzen": u1_muss_man_auf_stuhl_sitzen,
"v1_kleidungsstueck": v1_kleidungsstueck,
"v1_verantwortung_nachdenken": v1_verantwortung_nachdenken,
"v2_regeln_zettel": v2_regeln_zettel,
"v2_verantwortung_diskussion": v2_verantwortung_diskussion,
"v3_gemeinsame_aktion_ueberlegen": v3_gemeinsame_aktion_ueberlegen,
"v4_wohnraum_handeln_los": v4_wohnraum_handeln_los,
"v4_zurück_in_Gruppe": v4_zurück_in_Gruppe, 
"v5_runterzaehlen": v5_runterzaehlen,
"v6_glueckwunsch_handeln": v6_glueckwunsch_handeln,
"z_hoeren": z_hoeren,
"w1_willkommen": w1_willkommen,
"w2_konferenz_eroeffnet": w2_konferenz_eroeffnet,
"z_opt_redezeit_schenken": z_opt_redezeit_schenken,
"z_opt_restliche_redezeit_schenken": z_opt_restliche_redezeit_schenken,
"z_wer_am_meisten_geredet_hat": z_wer_am_meisten_geredet_hat,
"FX2_Time_Out_1" : FX2_Time_Out_1,
"FX1_Login1" : FX1_Login1,
"e03b_wie_erwachsen":e03b_wie_erwachsen,
"e11_systemfehler":e11_systemfehler,
"e11_zuzwinkern": e11_zuzwinkern,
"el12_anweisungen":el12_anweisungen,
"el13_hören": el13_hören,
"et1_tagesordnung_selbstversorgung": et1_tagesordnung_selbstversorgung,
"g6_opt_große_Geste":g6_opt_große_Geste,
"g7_opt_große_Geste":g7_opt_große_Geste,
"g8_opt_sprechen_dürfen":g8_opt_sprechen_dürfen,
"ra_zugestimmt":ra_zugestimmt,
"rb_abgelehnt":rb_abgelehnt,
"v3_Zettel_zeigen":v3_Zettel_zeigen,
"v4_Kleidung_warum":v4_Kleidung_warum,
"v7_abschluss_jubel":v7_abschluss_jubel,
"z_reden_fragen_1":z_reden_fragen_1,
"z_reden_fragen_2":z_reden_fragen_2,
"z_was_denkst_du":z_was_denkst_du,
"gong":gong,
"FX2_Screenshot_Sound": FX2_Screenshot_Sound,

};

export default SOUNDS;