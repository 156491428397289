import React from 'react'
import { ErrorHandler } from '../../components/common/Info/ErrorHandler'
import { PermanentInfo } from '../../components/common/Info/Info'
import { Footer } from '../../components/Footer/Footer'
import { JoinButton } from '../../components/Footer/JoinButton/JoinButton'
import { MuteButton } from '../../components/Footer/MuteButton/MuteButton'
import { Header } from '../../components/Header/Header'
import JitsiConnection from '../../components/JitsiConnection/JitsiConnection'
import { Localuser } from '../../components/Localuser/Localuser'
import { UserDragContainer } from '../../components/Localuser/LocalUserContainer'
import { PanWrapper } from '../../components/PanWrapper/PanWrapper'
import { Room } from '../../components/Room/Room'
import { Users } from '../../components/User/Users'
import { LocalStoreLogic } from '../../store/LocalStoreLogic'
import { SpielDichBottom, SpielDichTop } from '../../addons/Sde/SpielDich.jsx'
import { BackgroundFoyer } from '../../addons/Sde/BackgroundFoyer.jsx'

export const Foyer = ({id}) => {
	// componentDidMount() {
 //    	document.title = "Spiel Dich erwachsen!";  }
  	
	return (
		<React.Fragment>
			<ErrorHandler />
			 <PermanentInfo>
			 Damit das Spiel funktioniert:  <br />
- Lade die Website über den Browser Chrome. <br />
- Benutze Kopfhörer.  <br />
- Du brauchst einen Laptop oder Computer.  <br />
- Tablet und Smartphone funktionieren nicht.  <br />
- Falls deine Kamera und/oder dein Mikro nicht funktionieren, musst du diese in den Systemeinstellungen für den Browser freischalten.  <br />
- Wenn du im Laufe der Konferenz Ton oder Bildprobleme hast, lade die Website einfach neu. <br />


			</PermanentInfo> 
			{/*<Header>Chatmosphere</Header>*/}
			<JitsiConnection id={"foyer_"+id}/>
			<LocalStoreLogic />
			<Header>
				<SpielDichTop/>
    		</Header>
			<PanWrapper>
				
				<Room>
					{/*!-- <highlight componente -->*/}
					<Users />
					<UserDragContainer>
						<MuteButton />
						<Localuser audioRadius />
					</UserDragContainer>
				</Room>
				<BackgroundFoyer />
			</PanWrapper>
			
			<Footer>
				<SpielDichBottom />
				{/*<JoinButton joined={true} />*/}
				 
			</Footer>
		</React.Fragment>
	)
}
