import styled from 'styled-components'

const Form = styled.form`
  width: 840px;
  margin: auto;
  text-align: left;
`
const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: row;
`

const InputField = styled.input`
	height: 50px;
	background: ${props => props.theme.base['4']};
  color: ${props => props.theme.primary['1']};
	border: 1px solid ${props => props.theme.primary['1']};
  font-size: 1rem;
	box-sizing: border-box;
	border-radius: ${props => props.theme.radius} 0 0 ${props => props.theme.radius};
  padding-left: 20px;
  width: 100%;
  &:hover {
    background: ${props => props.theme.base['5']};
  }
  &:focus {
    outline: none;
    font-size:1rem;
  }
  &::placeholder {
    font-size: 1rem;
  }
  &:disabled {
    background-color: ${props => props.theme.base["3"]};
  }
`

const SendButton = styled.input`
  height: 50px;
  background: ${props => props.theme.primary['2']};
  border-radius: 0 ${props => props.theme.radius} ${props => props.theme.radius} 0;
  width: 151px;
  color: ${props => props.theme.base['6']};
  font-size: 1rem;
  border: none;
  &:hover {
    background-color: ${props => props.theme.primary['3']};
  }
`

export const ChatboxInputForm = ({ onSubmit, handleChange }) => {
	return (
		<Form onSubmit={onSubmit}>
			<Fieldset>
				<InputField
					name="ChatInput"
					type="text"
					onChange={handleChange}
          id="ChatInput"
				/>
				<SendButton name="sendButton" type="submit" value="Abschicken" />
			</Fieldset>
		</Form>
	)
}
