import React from 'react';
import styled from 'styled-components';
import { useLocalStore } from './../../../store/LocalStore';
import HighlightFile from './../../../addons/Sde/assets/stumm.png'


const Indicator = styled.div`
  position:absolute;
  background-image:url(${HighlightFile});
  top:118px;
  left:36px;
  width:250px;
  height:210px;
  filter: drop-shadow(5px 3px 3px #222);
  z-index:0;
`

export const MuteIndicator = () => {

  const {toggleMute} = useLocalStore()

  const handleClick = () => {
    toggleMute()
  } 

  return (
    <Indicator onClick={handleClick}>
      {/*<Muted />*/}
    </Indicator>
  )
}

