import { useLocalStore } from "../../store/LocalStore"
import { useConferenceStore } from "../../store/ConferenceStore"
import { useState, useEffect, useRef } from 'react'

import styled from 'styled-components';
import background_file from "./assets/raum_rechteck.jpg";
import background_file_foyer from "./assets/foyer.jpg";



const BackgroundComponent = styled.div`
  //background-image:url(${background_file});

  background-image:url( ${(props) => (props.foyer ?  background_file_foyer:background_file )});
  
  z-index:-10;
  position:absolute;
  width:100%;
  height:100%;
`
export const Background = (props) => 
{

  //const ref = useRef(null);
  const currentBackground = useLocalStore((store) =>  store.currentBackground)
  const [foyer , setFoyer] = useState(false)

  useEffect(() => {

    if (currentBackground !="") {
      setFoyer(true);
    }

  }, [currentBackground]);



  return(
    <BackgroundComponent  foyer={foyer} />
  )
}
