import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Wave } from './../../assets/wave.svg'
import { Footer } from '../../components/Footer/Footer'
import {BigHeadline} from './../../components/common/BigHeadline'
import { SubHeadline } from '../../components/common/SubHeadline'
import background_end from "../../addons/Sde/assets/ende_sm.jpg" //.png" 

const BackgroundComponent = styled.div`
overflow-y: scroll;
height: 100%;
`

const Img = styled.img`
	margin-left: auto;
    margin-right: auto;
	`

const BigHeadContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

const CenterContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	//background-image: url(${background_end});
	background-repeat: no-repeat;
	background-position: center;
`

const FormContainer = styled.div`
	margin-top: 40px;
`

export const NotWorking = () => {
	return (
		<React.Fragment>
			
			<CenterContainer>
			<BigHeadContainer>
				<Wave />
				<BigHeadline>Konferenz </BigHeadline>
			</BigHeadContainer>
			<SubHeadline>Liebes Kind, lieber Jugendlicher</SubHeadline>
				
			<div style={{width:"600px", marginLeft: "auto",marginRight: "auto"}}>
				<p>jetzt wolltest du dich erwachsen spielen und bist hier gelandet. Im
				Abseits. Im Off.</p>

				<p>Schon wieder bestimmst nicht du, sondern die Technik hat leider das Sagen.</p>

				<p>Und diese Technik sagt uns, dass deine Ausrüstung (Computer, Internet,
				Browser..) nicht ganz ausreicht für unser Spiel. Du hörst das
				Computersystem Malin nicht oder die anderen Spieler*innen hören dich
				nicht? Deine Kamera öffnet sich nicht, oder du bleibst immer wieder hängen?
				Das tut uns sehr leid. Aber wir haben darauf keinen Einfluss und können dir jetzt leider erstmal nicht mehr weiter helfen.</p>

				<p>Bitte hab dafür Verständnis und schreib uns eine mail an:
				info@pulk-fiktion.de, damit wir wissen, dass du aus dem System geflogen
				bist und wir dir eine Eintrittskarte für unsere analogen Stücke als
				Ersatz anbieten können.</p>

				<p>Danke.</p>
				<p>Mach es gut!</p>
			</div>
			</CenterContainer>
		</React.Fragment>
	)
}
