import * as React from 'react'
import { Header } from './components/Header/Header'
import styled from 'styled-components'

import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Home } from "./pages/Home/Home"
import { Enter } from "./pages/Enter/Enter"
import { Session } from "./pages/Session/Session"
import { Foyer } from "./pages/Foyer/Foyer"
import { NotWorking } from "./pages/NotWorking/NotWorking"
import { SessionOver } from "./pages/SessionOver/SessionOver"
import { Setup } from "./pages/Setup/Setup"

import { Schaltzentrale } from './addons/Sde/Schaltzentrale.jsx'
import { SchaltzentraleFoyer } from './addons/Sde/SchaltzentraleFoyer.jsx'

const AppContainer = styled.div`
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  cursor: default;
`


function App() {
  return (
    <AppContainer>
      <Router>
        <Switch>
          {/* <Route path="/enter/:id">
            <Enter />
          </Route> */}

          <Route path="/vorstellung/:id"
            render={({match}) => (
            <Session id={match.params.id} />
            )}
          />
          <Route path="/foyer/:id"
            render={({match}) => (
            <Foyer id={match.params.id} />
            )}
          />

          <Route path="/ende/">
            <SessionOver />
          </Route>

          <Route path="/funktioniert_nicht/">
            <NotWorking />
          </Route>

          <Route path="/schaltzentrale/:id"
            render={({match}) => (
            <Schaltzentrale id={match.params.id} />
            )}
          />

          <Route path="/technikcheck/">
            <Setup />
          </Route>

          <Route path="/schaltzentrale_foyer/:id"
            render={({match}) => (
            <SchaltzentraleFoyer id={match.params.id} />
            )}
          />

          <Route path="/foyer/:id"
            render={({match}) => (
            <Foyer id={"foyer_"+match.params.id} />
            )}
          />

          <Route path="/">
            <Header>Konferenz</Header>
            <Home />
          </Route>
        </Switch>
      </Router>
    </AppContainer>
  )
}

export default App
