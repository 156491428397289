import React from 'react'
import { ErrorHandler } from '../../components/common/Info/ErrorHandler'
import { Info } from '../../components/common/Info/Info'
import { Footer } from '../../components/Footer/Footer'
import { JoinButton } from '../../components/Footer/JoinButton/JoinButton'
import { MuteButton } from '../../components/Footer/MuteButton/MuteButton'
import { Header } from '../../components/Header/Header'
import JitsiConnection from '../../components/JitsiConnection/JitsiConnection'
import { Localuser } from '../../components/Localuser/Localuser'
import { UserDragContainer } from '../../components/Localuser/LocalUserContainer'
import { PanWrapper } from '../../components/PanWrapper/PanWrapper'
import { Room } from '../../components/Room/Room'
import { AdditionalRoom } from '../../components/AdditionalRoom/AdditionalRoom'
import { Users } from '../../components/User/Users'
import { LocalStoreLogic } from '../../store/LocalStoreLogic'
import { SpielDichBottom, SpielDichTop } from '../../addons/Sde/SpielDich.jsx'
import { Background } from '../../addons/Sde/Background.jsx'

export const Session = (id) => {
	// componentDidMount() {
 //    	document.title = "Spiel Dich erwachsen!";  }
  	
	return (
		<React.Fragment>
			<ErrorHandler />
			{/*	<Info>
				Welcome to our Prototype
				<br />
				Please use <b>Safari</b> or <b>Chrome</b> for now for a stable Experience
			</Info>*/}
			{/*<Header>Chatmosphere</Header>*/}
			<JitsiConnection id={id}/>
			<LocalStoreLogic />
			<Header>
				<SpielDichTop/>
    		</Header>
			<PanWrapper>
				
				<Room>
					{/*!-- <highlight componente -->*/}
					<Users />
					<UserDragContainer>
						<MuteButton />
						<Localuser audioRadius />
					</UserDragContainer>
					<AdditionalRoom />
				</Room>
				
				<Background />
			</PanWrapper>
			
			<Footer>
				<SpielDichBottom />
				{/*<JoinButton joined={true} />*/}
			</Footer>
		</React.Fragment>
	)
}
