import { useCallback, useEffect } from 'react';
import { ChatboxInputContainer } from './ChatboxContainer';
import { MessageBox } from './MessageComponent';
import { Timer } from './TimerComponent.jsx';
import { PlaybackContainer } from './AudioElements.jsx';
import { useConferenceStore } from '../../store/ConferenceStore';
import { useConnectionStore } from "../../store/ConnectionStore"
import { useLocalStore } from "../../store/LocalStore"


export const SpielDichTop = () => {

  const hasCountdown = useLocalStore((store) =>  store.hasCountdown)
  const hasMessage = useLocalStore((store) =>  store.hasMessage)

  return(
    <>
    <>{hasMessage && <MessageBox />}</>
    <>{hasCountdown && <Timer />}</>
    </>
  )
}



export const SpielDichBottom = () => {

  const hasChatbox = useLocalStore((store) => store.hasChatbox)

  return(
    <>
    <PlaybackContainer />
    {hasChatbox && <ChatboxInputContainer />}
    </>
  )
}

