import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Wave } from './../../assets/wave.svg'
import { Footer } from '../../components/Footer/Footer'
import {BigHeadline} from './../../components/common/BigHeadline'
import { SubHeadline } from '../../components/common/SubHeadline'
import { Info } from '../../components/common/Info/Info'

import background_end from "../../addons/Sde/assets/ende_sm.jpg" //.png" 

const BackgroundComponent = styled.div`
overflow-y: scroll;
height: 100%;
`

const Img = styled.img`
	margin-left: auto;
    margin-right: auto;
	`

export const SessionOver = () => {
	return (
		<BackgroundComponent>
 			<Img width="100%" src={background_end}></Img>
			 </BackgroundComponent>
	)
}
