import { ChatboxInputForm } from './ChatboxInputForm';
import { useConferenceStore } from "../../store/ConferenceStore"
import { useConnectionStore } from "../../store/ConnectionStore"

export const ChatboxInputContainer = () => {

	const handleChange = (e) => {
		//setName(e.target.value)
	}

	const onSubmit = (e) => {
		e.preventDefault()
	    const [input] = e.target
	    const ChatInputValue  =  input.children[0].value; //! checkme
		input.children[0].value = '';
		
		if(ChatInputValue.length > 0) {
		
		const conference = useConferenceStore.getState().conferenceObject
		const myId = conference?.myUserId()
		//const username = conference?.user._displayName

		//! no sendcommandonce available?!
	    conference?.sendCommand("msg_from_user", { value: 0, attributes: {
				content: ChatInputValue,
				//user_name: username,
				user_id: myId
			}

		})

		
		}
	}

  return <ChatboxInputForm onSubmit={onSubmit} handleChange={handleChange} />
}