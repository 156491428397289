import React from 'react';
import styled from 'styled-components';
import HighlightFile from './../../../addons/Sde/assets/highlight_speaker.png'


const Indicator = styled.div`
  position:absolute;
  background-image:url(${HighlightFile});
  background-size: cover;
  background-repeat: no-repeat;
  top:-54px;
  left:-201px;
  width:810px;
  height:394px;

  z-index:-1;
`

export const SpeakerIndicator = () => {
  return (
    <Indicator />
    
  )
}

