import { useLocalStore } from "../../store/LocalStore"
import { useConferenceStore } from "../../store/ConferenceStore"
import { useState, useEffect, useRef } from 'react'
import SOUNDS from './audios.js'
import MUSIC from './music.js'

export const reset_sound = () => {
    useLocalStore.setState({currentSound: ""})
}

export const reset_music = () => {
  useLocalStore.setState({currentMusic: ""})
}

const PlaybackAudio = ({ url, isActive = false, volume = 1 }) => {
  const ref = useRef(null);
  
  useEffect(() => {
    if (!ref.current) return;

    if (isActive) {
      ref.current.play();
    } else {
      ref.current.pause();
      ref.current.currentTime = 0;
    }
  }, [isActive]);

  //console.log('loaded', url)

  return <div>
           <audio ref={ref} autoPlay={false} src={url} onEnded={reset_sound}/>
         </div>
}

const PlaybackMusic = ({ url, isActive = false, volume = 1 }) => {
  const ref = useRef(null);
  const currentMusicVolume = useConferenceStore(state => state.music_volume)
  
  useEffect(() => {
    if (!ref.current) return;

    ref.current.volume = currentMusicVolume
    if (isActive) {
      ref.current.play();
    } else {
      ref.current.pause();
      ref.current.currentTime = 0;
    }
  }, [isActive, currentMusicVolume]);

  //console.log('loaded', url)

  return <div>
           <audio ref={ref} autoPlay={false} src={url} onEnded={reset_music}/>
         </div>
}




export const PlaybackContainer = () => {
  const { currentSound, currentMusic } = useLocalStore(({ currentSound, currentMusic }) => ({ currentSound, currentMusic }))
  const setCurrentSound = useLocalStore(state => state.setCurrentSound)
  const setCurrentMusic = useLocalStore(state => state.setCurrentMusic)
 

  return (
    <>
    <>
      {Object.entries(SOUNDS).map(([name, url]) => (<PlaybackAudio key={url} url={url} isActive={currentSound === name} />))}
    </>
    <>
      {Object.entries(MUSIC).map(([name, url]) => (<PlaybackMusic key={url} url={url} isActive={currentMusic === name} />))}
    </>
    </>
  )
}