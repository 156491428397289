import styled from 'styled-components';
import background_file_foyer from "./assets/foyer.jpg" 

const BackgroundComponent = styled.div`
  background-image:url(${background_file_foyer});
  z-index:-10;

  position:absolute;
  width:100%;
  height:100%;
`
export const BackgroundFoyer = () => 
{
  return(
    <BackgroundComponent />
  )
}
