import m1_Jubel_Klatsch_Musik from "./assets/music/m1_Jubel_Klatsch_Musik.mp3"
import m1_einlass_musik from "./assets/music/m1_einlass_musik.mp3"
import m2_Jubel_Klatsch_Prost_Musik_2 from "./assets/music/m2_Jubel_Klatsch_Prost_Musik_2.mp3"
import m2_Musik_Willkommen from "./assets/music/m2_Musik_Willkommen.mp3"
import m3_Action_Part1 from "./assets/music/m3_Action_Part1.mp3"
import m3_Action_Part2 from "./assets/music/m3_Action_Part2.mp3"
import m4_Umzugsmusik from "./assets/music/m4_Umzugsmusik.mp3"
import m4_Login_Beat from "./assets/music/m4_Login_Beat.mp3"
import m6_Gesten from "./assets/music/m6_Gesten.mp3"
import m7_nachdenkmusik from "./assets/music/m7_nachdenkmusik.mp3"
import m8_Action_Part3 from "./assets/music/m8_Action_Part3.mp3"
import m9_Action_Part4 from "./assets/music/m9_Action_Part4.mp3"
import abgestimmt from "./assets/music/abgestimmt.mp3"

const MUSIC = {
	"m1_Jubel_Klatsch_Musik": m1_Jubel_Klatsch_Musik,
	"m1_einlass_musik": m1_einlass_musik,
	"m2_Jubel_Klatsch_Prost_Musik_2": m2_Jubel_Klatsch_Prost_Musik_2,
	"m2_Musik_Willkommen": m2_Musik_Willkommen,
	"m3_Action_Part1": m3_Action_Part1,
	"m3_Action_Part2":m3_Action_Part2,
	"m4_Umzugsmusik":m4_Umzugsmusik,
	"m4_Login_Beat":m4_Login_Beat,
	"m6_Gesten": m6_Gesten,
	"m7_nachdenkmusik": m7_nachdenkmusik,
	"m8_Action_Part3": m8_Action_Part3,
	"m9_Action_Part4": m9_Action_Part4,
	"abgestimmt" : abgestimmt,
};

export default MUSIC;