import React, { useRef } from "react"
import styled from "styled-components"
import { useLocalStore } from "../../store/LocalStore"
import LocalVideo from "./LocalVideo"
import LocalAudio from "./LocalAudio"
import { MuteIndicator } from "./elements/MuteIndicator"
import { HighlightIndicator } from "./elements/HighlightIndicator"
import { SpeakerIndicator } from "./elements/SpeakerIndicator"
import { ReloadHint } from "../ReloadHint/ReloadHint"
import { panOptions } from "./../PanWrapper/panOptions"
import { AudioRadius } from "./elements/AudioRadius"
import { NameContainer } from "./elements/NameContainer"
import { Flipped } from "./elements/Flipped"

const Container = styled.div`
  width: ${panOptions.user.size.x}px;
  height: ${panOptions.user.size.y}px;
  position: absolute;
  border: 4px solid #D9DBEB;
  border-radius: 50%;
  cursor: default;
  &:active {
    cursor: default;
  }
`

interface ILocaluser {
  // panChanged: (callback: (params) => void) => void
  audioRadius?: boolean
}


export const Localuser: React.FC<ILocaluser> = ({audioRadius = false}) => {

  const audioTrack = useLocalStore((store) => store.audio)
  const videoTrack = useLocalStore((store) => store.video)
  const isMute = useLocalStore((store) => store.mute)

  const localUserNode = useRef<HTMLDivElement>(null)

  const isMuted = useLocalStore((store) => store.muted)
  const isLocked = useLocalStore((store) => store.locked)
  const isFlipped = useLocalStore((store) => store.flipped)
  const hasChatbox = useLocalStore((store) => store.hasChatbox)
  const isHighlighted = useLocalStore((store) => store.highlighted)
  const isSpeaker = useLocalStore((store) => store.highlighted_speaker)
  const hasCountdown = useLocalStore((store) => store.hasCountdown)

  const currentMusic = useLocalStore((store) => store.currentMusic)
  const currentSound = useLocalStore((store) => store.currentSound)
  const message = useLocalStore((store) => store.message)


  return (
    <Container
      ref={localUserNode}
    >
      {isHighlighted && <HighlightIndicator/> }
      {isSpeaker && <SpeakerIndicator/> }
      {audioRadius && <AudioRadius/>}
      
      <Flipped isFlipped={isFlipped}>
      {videoTrack && (
        <LocalVideo key={videoTrack.track.id} track={videoTrack} />
      )}
      </Flipped>
      
      <ReloadHint />
      {audioTrack && (
        <LocalAudio key={audioTrack.track.id} track={audioTrack} />
      )}
      {isMute && <MuteIndicator />}
      <NameContainer />
      
      
		</Container>
	);
}
